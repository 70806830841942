<template>
  <div class="resume">
    <router-link to="/">Home</router-link>
    <h1>Resume</h1>
    <QuickLinks :contact="true"/>
    <div class="resume-container">
        <div class="entry">
            <h2 class="role">Associate Creative Director, Indeed</h2>
            <h3>2019 – Present</h3>
            <p class="description">
                Led design of a year-long series of experiential activations across the United States. Work included the creation of a new identity, printed and digital collateral, and a comprehensive social media campaign to promote the events. Worked with a small team of event planners to bring the Indeed brand to life through interior design, furniture, event staff wardrobe, and more.
            </p>
        </div>
        <div class="entry">
            <h2 class="role">Associate Creative Director, R/GA</h2>
            <h3>2018 – 2019</h3>
            <p class="description">
                Led creative development of digital and social campaigns. Worked across disciplines to win new business pitches. Built upon and improved campaigns for clients such as Racetrac, Crocs, and Frito-Lay brands.
                <br/><br/>
                Mentored junior designers and interns, while creating an environment of constructive critique within a small team of creatives.
            </p>
        </div>
        <div class="entry">
            <h2 class="role">Senior Art Director, T3</h2>
            <h3>2016 – 2018</h3>
            <p class="description">
                Partnered with user research teams to elevate design across Allstate’s product experiences and developed new products to address the changing landscape of the insurance industry. Worked closely with strategy teams to craft the messaging around said products to bring them to market.
                <br/><br/>
                Participated in design sprints and several client-facing workshops to create innovative products in as little as 10 hours. Mentored designers to create a collaborative and inclusive culture of design criticism and iterative experimentation.
            </p>
        </div>
        <div class="entry">
            <h2 class="role">Senior Art Director, BBDO</h2>
            <h3>2011 – 2016</h3>
            <p class="description">
                Worked closely with clients and partner agencies to launch and implement a global rebrand for HP. This comprehensive design system introduced an entirely new visual vocabulary for the company. From typography to photography, this new system united HP’s voice across the globe in digital, print, TV, social, and out of home campaigns.
                <br/><br/>
                Created online content and lead digital teams in the development of global and national campaigns for Mobil 1, Lowe’s, and the ALS Association of New York.
            </p>
        </div>
        <div class="entry">
            <h2 class="role">Art Director, Ogilvy</h2>
            <h3>2008 – 2011</h3>
            <p class="description">
                Lead creative development of the IBM Watson identity. Work included a physical installation for use on Jeopardy, an identity which is still in use today, a global advertising campaign, and the design and launch of ibmwatson.com.
                <br/><br/>
                Helped launch IBM’s award-winning Smarter Planet campaign, creating an entirely new branding system for use in digital, print, and TV campaigns across the globe.
            </p>
        </div>
        <div class="entry">
            <h2 class="role">BFA in Graphic Design, RISD</h2>
            <h3>2004 – 2008</h3>
        </div>
        <div class="entry">
            <h2 class="role">Skills</h2>
            <p class="description">
                I’m fully versed in Sketch, Invision, Zeplin, and the Adobe Creative Suite, including After Effects. I have hands on experience using Javascript, CSS, HTML, and Github to create working product prototypes and my own side projects. I also play around with C# in Unity.
            </p>
        </div>
    </div>
  </div>
</template>

<script>
import QuickLinks from '@/components/QuickLinks.vue'
export default {
    name: 'Resume',
    components: {
        QuickLinks
    }
}
</script>

<style>
.resume {
    grid-area: 1/1/3/1;
    margin-top: 60px;
    padding: 0 20px;
}
.resume a {
    font-family: 'IBM Plex Sans';
}
.resume-container {
    margin: 60px 0;
    max-width: 650px;
}
.resume-container h3 {
    color: #66666E;
}
p.description {
    font-size: 16px;
}
.entry {
    margin: 60px 0
}
</style>